import React from "react";
import { useState, useEffect } from "react";
import { Select, Spin } from "antd";

const SearchableSelect = () => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [searchText, setSearchText] = useState("");
    let searchTimeout = null; // برای جلوگیری از ارسال درخواست‌های مکرر

    useEffect(() => {
        if (!searchText) return;

        setLoading(true);

        // ایجاد تاخیر برای جلوگیری از درخواست‌های مکرر
        searchTimeout = setTimeout(async () => {
            try {
                const response = await fetch(`/api/search?q=${searchText}`);
                const data = await response.json();

                // تبدیل داده‌های API به فرمت مناسب برای Select
                const formattedOptions = data.map((item) => ({
                    label: item.name,
                    value: item._id,
                }));

                setOptions(formattedOptions);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }, 500); // تاخیر ۵۰۰ میلی‌ثانیه‌ای

        return () => clearTimeout(searchTimeout); // حذف تایمر قبلی هنگام تغییر مقدار `searchText`
    }, [searchText]);

    return (
        <Select
            showSearch
            allowClear
            placeholder="جستجو و انتخاب کنید"
            style={{
                width: 160,
            }}
            loading={loading}
            onSearch={(value) => setSearchText(value)} // مقدار ورودی را در `searchText` ذخیره می‌کند
            options={options}
            value={selectedValue}
            onChange={setSelectedValue}
            notFoundContent={loading ? <Spin size="small" /> : "نتیجه‌ای یافت نشد"}
        />
    );
};

export default SearchableSelect;
