import React,{useEffect,useState} from 'react';
import {Drawer, message, Spin} from "antd";
import "./DiscountSystem.scss";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {createCodes_api, deleteCodes_api, getCodes_api} from "../../Services/Services";

function CodeCard({code,deleteCode}){
    return(
        <div className="codes-card">
            <div className="codes-card-val">
                <span>{code.code}</span>
            </div>
            <div className="codes-card-footer">
                <div className="footer-actions">
                    <div className="trash-icon-sec clickable" onClick={e=>{
                        deleteCode(code._id)
                    }}>
                        <img src={trashWhiteIcon} alt=""/>
                    </div>
                    <CopyToClipboard
                        text={code.code}
                        onCopy={e => {
                            message.success({
                                content: `کد کپی شد`,
                                className: 'toast-class'
                            });
                        }}>
                        <span className="code-copy clickable">کپی</span>
                    </CopyToClipboard>
                </div>
                <label>{`تعداد استفاده ${code.used_count} بار`}</label>
            </div>

        </div>
    )
}

function Codes({id}){

    useEffect(e=>{
        getCodes()
    },[])

    const [spin,set_spin] = useState(false)
    const [char,set_char] = useState("")
    const [codes,set_codes] = useState([])

    const getCodes = async() =>{
        set_spin(true)
        try {
            const res= await getCodes_api(id)
            set_codes(res.data.data)
            set_spin(false)
        }catch(e){
            set_spin(false)
            console.log(e)
        }
    }

    const createCodes = async() =>{
        set_spin(true)
        try {
            const res= await createCodes_api({code:char},id)
            getCodes()
        }catch(e){
            set_spin(false)
            console.log(e)
        }
    }

    const deleteCodes = async(cId) =>{
        set_spin(true)
        try {
            await deleteCodes_api(cId)
            getCodes()
        }catch(e){
            set_spin(false)
            console.log(e)
        }
    }
    return(
        <div className="codes-container">
                <div className="header-codes">
                    <span>لیست کدها</span>
                </div>
                <Spin spinning={spin}>
                    <>
                        <div className="generate-codes ">
                            <input
                                value={char}
                                onChange={e=>set_char(e.target.value)}
                                type="text" name="" id=""/>
                            <span onClick={e=>{
                                createCodes()
                            }} className="clickable">تولید کد</span>
                        </div>
                        <div className="list-codes">
                            {codes?.map(c=>{
                                return(
                                    <CodeCard  deleteCode={deleteCodes} code={c}/>
                                )
                            })}
                        </div>
                    </>

                </Spin>

        </div>

    )
}

export default Codes;
