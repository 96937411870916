import React, {Fragment, useEffect, useRef, useState} from 'react';
import "./DiscountSystem.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import searchIcon from "../../assets/dumbbell_image/V2/search_icon_v2.svg"
import caretDownIcon from "../../assets/dumbbell_image/V2/caret_down_icon.svg"
import chatIcon from "../../assets/dumbbell_image/V2/chat_icon.svg"
import picIcon from "../../assets/dumbbell_image/V2/pic_icon.svg"
import profileIcon from "../../assets/dumbbell_image/V2/profile_icon.svg"
import emptyIcon from "../../assets/dumbbell_image/V2/empty_icon_v2.svg"
import loadingGif from "../../assets/dumbbell_image/V2/page_spinner_v2.gif"

import messageIcon from "../../assets/dumbbell_image/message-icon.png"
import traineeFaceIcon from "../../assets/dumbbell_image/sample-trainee-face.png";
import loadingImage from "../../assets/loading/load_2.gif"
import deleteIcon from "../../assets/card_icon/delete_icon.svg";
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png"
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';
import useWindowDimensions from "../../Hook/useWindowDimensions";
import {Drawer, message, Spin} from "antd";
import DumbbellAddTrainee from "../DumbbellAddTrainee/DumbbellAddTrainee";
import DumbbellChatTrainer from "../DumbbellChatTrainer/DumbbellChatTrainer";
import {useHistory, useLocation, useParams} from "react-router";
import {
    acceptBookingReceiptFromBook_api,
    bookSeenApi,
    changePlanReceiptStatus_api,
    changeStatus_api,
    changeStatusFromBook_api,
    deleteTraineeApi,
    getMediasApi,
    getTrainerProfileApi,
    getUnreadMessage_api,
    mediaChallengeDeleteApi,
    mediaDeleteApi,
    mediaRedDeleteApi,
    ordersRequestChangeStatusApi,
    ordersRequestListApi,
    ordersRequestSeenApi,
    planSeenApi,
    requestsPlan_api,
    requestsTurn_api,
    seenMediaApi,
    subscriberRequestChangeStatusApi,
    subscribersRequestListApi,
    subscriptionRequestListApi,
    subscriptionRequestSeenApi,
    traineeListApi
} from "../../Services/Services";
import defaultUser from "../../assets/images/default_user.png";
import useOnScreen from "../../Components/useOnScreen/useOnScreen";
import DumbbellBoxTrainer from "../DumbbellBoxTrainer/DumbbellBoxTrainer";

import {CodeSandboxOutlined, UserOutlined} from '@ant-design/icons';
import moment from "moment-jalaali";
import {Setup} from "../../Services/Setup";
import {useDispatchError} from "../../Hook/useDispatchError";
import useLongPress from "../../Hook/useLongPress";
import {checkPlanEndDate, convertMonthNumberToName, numberWithCommas, setAntdDrawerWidth} from "../../Services/utils";
import {Collapse} from "react-collapse";
import sampleFace from "../../assets/images/default_user.png";
import Zoom from "react-medium-image-zoom";
import Campaign from "./Campaign";
import DiscountCode from "./DiscountCode";

let page = 0;
let search = "";
let type = "all";
let triggerID = "";



function DiscountSystem() {
    const handleChangeFilter = (page, t) => {
    }

    const [activeTab,set_activeTab] = useState("campaign") // campaign | discount
    const handleClickOnTab = (t)=>{
        set_activeTab(t)
    }

    const [searchInput, set_searchInput] = useState("")

    const handleSearch = (word) => {
        set_searchInput(word)
        if (word.length > 2) {
            page = 0;
            search = word;
            // getData()
        }
        if (word.length === 0) {
            page = 0;
            search = "";
            // getData()
        }
    }

    return(
        <div>
            <DumbbellHeader title="سیستم تخفیف" changeFilter={handleChangeFilter}/>
            <div className="discount-system-body">
            <div className="tabs-row">
                <div onClick={e => handleClickOnTab("discount")}
                     className={activeTab === "discount" ? "tabs-item clickable active-tab" : "tabs-item clickable"}>
                    <span>کدهای تخفیف</span>
                </div>
                <div onClick={e => handleClickOnTab("campaign")}
                     className={activeTab === "campaign" ? "tabs-item clickable active-tab" : "tabs-item clickable"}>
                    <span>جشنواره ها</span>
                </div>
            </div>

                {/*<div className="search-row">*/}
                {/*    <img src={searchIcon} alt=""/>*/}
                {/*    <input*/}
                {/*        value={searchInput}*/}
                {/*        onChange={e => handleSearch(e.target.value)}*/}
                {/*        type="text" name="" id="" placeholder="جستجو"/>*/}
                {/*</div>*/}


                {activeTab==="campaign"?
                    <div>
                        <Campaign/>
                    </div>
                    :null}

                {activeTab==="discount"?
                    <div>
                        <DiscountCode/>
                    </div>
                    :null}
        </div>



        </div>
    )
}


export default DiscountSystem;
