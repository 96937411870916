import React, {useState,useEffect} from "react"
import plusWhiteIcon from "../../assets/dumbbell_image/plus-white-icon.png";
import DumbbellAddSubscription from "../DumbbellAddSubscription/DumbbellAddSubscription";
import {Drawer, message, Select, Spin} from "antd";
import {
    createCampaign_api, createDiscountCode_api, deleteDiscountCode_api,
    getCampaigns_api,
    getDiscountCode_api,
    listOption_api,
    updateCampaign_api, updateDiscountCode_api
} from "../../Services/Services";
import SearchableSelect from "../../Components/SearchableSelect/SearchableSelect";
import {convertCampaignType} from "../../Services/utils";
import Codes from "./Codes";
import trashWhiteIcon from "../../assets/dumbbell_image/trash_white_icon.png";

const moment = require("moment-jalaali");


function CampaignCard({campaign,edit,deleteCode}){

    return(
        <div className="campaign-card" >
            <div className="campaign-card-title">
                <span>{campaign.title}</span>
            </div>
            <div className="campaign-card-type">
                <span>{convertCampaignType(campaign.type)}</span>
            </div>
            <div className="campaign-card-footer">
                <div className="campaign-card-footer-actions">

                    <div className="discount-edit-btn clickable" onClick={e=>{
                        edit(campaign)
                    }}>
                        <span>ویرایش</span>
                    </div>
                    <div className={`campaign-card-status ${campaign.status==="true"?"campaign-card-status-active":"campaign-card-status-deActive"}`}>
                        <span>{campaign.status==="true"?"فعال":"غیرفعال"}</span>
                    </div>

                    <div className="trash-icon-sec clickable discount-trash-btn" onClick={e=>{
                        deleteCode(campaign._id)
                    }}>
                        <img src={trashWhiteIcon} alt=""/>
                    </div>
                </div>
                <div className="campaign-card-date">
                    <span>{moment(campaign.end_date).format("jYYYY/jMM/jDD")}</span>
                    <span>تا</span>
                    <span>{moment(campaign.start_date).format("jYYYY/jMM/jDD")}</span>
                </div>

            </div>
        </div>
    )
}

function convertToSelectTo(list){
    return list.map(l=>{
        return {label:l.title,value:l._id}
    })
}

function DiscountCode() {

    //init
    useEffect(e=>{
        getCampaigns()
        getOptions()
    },[])

    //form state
    const [title,set_title] = useState("")
    const [max_count,set_max_count] = useState("")
    const [max_consumed,set_max_consumed] = useState("")
    const [campId,set_campId] = useState(null)
    const [services,set_services] = useState([])
    const [min_buy_amount,set_min_buy_amount] = useState("")
    const [start_date,set_start_date] = useState("")
    const [end_date,set_end_date] = useState("")
    const [type,set_type] = useState("before_buy")
    const [value_type,set_value_type] = useState("amount")
    const [value,set_value] = useState("")
    const [status,set_status] = useState("false")

    const [options,set_options] = useState([])
    const [optionsValue,set_optionsValue] = useState([])
    const [data,set_data] = useState([])
    const getOptions = async()=>{
        try{
            const res = await listOption_api()
            let opt = convertToSelectTo(res.data.data)
            set_options(opt)
        }catch (e) {
            console.log(e)
        }
    }



    const editCampaign = (c)=>{
        fillForm(c)
        set_addDrawerVisible(true)
    }

    const fillForm = (c)=>{
        set_title(c.title);
        set_max_count(c.max_count);
        set_max_consumed(c.max_consumed);
        set_campId(c._id);
        set_services(c.services);
        let ids = c.services.map(s=>s.value)
        set_optionsValue(ids)
        set_min_buy_amount(c.min_buy_amount)
        set_start_date(moment(c.start_date).format("jYYYY-jMM-jDD"))
        set_end_date(moment(c.end_date).format("jYYYY-jMM-jDD"))
        set_type(c.type)
        set_value_type(c.value_type)
        set_value(c.value)
        set_status(c.status)
    }

    const resetForm = (c)=>{
        set_title("");
        set_max_count("");
        set_max_consumed("");
        set_campId(null);
        set_optionsValue([])
        set_services([]);
        set_min_buy_amount("")
        set_start_date("")
        set_end_date("")
        set_type("before_buy")
        set_value_type("amount")
        set_status("false")
    }

    const getCampaigns = async()=>{
        try{
            const res = await getDiscountCode_api()
            console.log("ddd : ",res.data.data.discountCode)
            set_data(res.data.data.discountCode)
        }catch (e) {
            console.log(e)
        }
    }



    //code drawer
    const [codeDrawerVisible,set_codeDrawerVisible] = useState(false)
    const showCodeDrawer = ()=>{
        set_codeDrawerVisible(true)
    }
    const okCodeDrawer = ()=>{

        set_codeDrawerVisible(false)
    }
    const cancelCodeDrawer = ()=>{
        set_codeDrawerVisible(false)
    }

    //add drawer
    const [addDrawerVisible,set_addDrawerVisible] = useState(false)
    const showAddDrawer = ()=>{
        resetForm()
        set_addDrawerVisible(true)
    }
    const okAddDrawer = ()=>{

        set_addDrawerVisible(false)
    }
    const cancelAddDrawer = ()=>{
        set_addDrawerVisible(false)
    }



    const handleChange = (value) => {
        console.log(`selected ${value}`);
        set_optionsValue(value)
    };


    const handleEditCampaign = async ()=>{

        //validation
        if(title.length<1){
            return message.warn({
                content: `لطفا عنوان را وارد نمایید`,
                className: 'toast-class'
            });
        }

        if(optionsValue.length<1){
            return message.warn({
                content: `لطفا بروی خدمات را وارد نمایید`,
                className: 'toast-class'
            });
        }

        if(min_buy_amount.length<1){
            return message.warn({
                content: `لطفا حداقل پرداخت را وارد نمایید`,
                className: 'toast-class'
            });
        }

        if(start_date.length<1 || end_date.length<1){
            return message.warn({
                content: `لطفا تاریخ شروع و پایان را وارد نمایید`,
                className: 'toast-class'
            });
        }


        if( max_count.length<1 || max_consumed.length<1){
            return message.warn({
                content: `لطفا تعداد و حداکثر تعداد استفاده را وارد نمایید`,
                className: 'toast-class'
            });
        }

        const selectedObjects = optionsValue.map((val) =>
            options.find((option) => option.value === val)
        );

        let obj ={
            title:title,
            services:selectedObjects,
            min_buy_amount:min_buy_amount,
            start_date:moment(start_date, "jYYYY-jMM-jDD").toISOString(),
            end_date:moment(end_date, "jYYYY-jMM-jDD").toISOString(),
            type:type,
            max_count:max_count,
            max_consumed:max_consumed,
            value_type:value_type,
            value:value,
            status:status
        }

        try{
            console.log("obj ",obj)
            await updateDiscountCode_api(obj,campId)
            getCampaigns()
            okAddDrawer()
            return message.success({
                content: `کد تخفیف ویرایش شد`,
                className: 'toast-class'
            });
        }catch (e) {
            console.log(e)
            return message.warn({
                content: `مشکل در ویرایش کد تخفیف`,
                className: 'toast-class'
            });
        }

    }



    const addCampaign = async ()=>{

        //validation
        if(title.length<1){
            return message.warn({
                content: `لطفا عنوان را وارد نمایید`,
                className: 'toast-class'
            });
        }

        if(optionsValue.length<1){
            return message.warn({
                content: `لطفا بروی خدمات را وارد نمایید`,
                className: 'toast-class'
            });
        }

        if(min_buy_amount.length<1){
            return message.warn({
                content: `لطفا حداقل پرداخت را وارد نمایید`,
                className: 'toast-class'
            });
        }

        if(start_date.length<1 || end_date.length<1){
            return message.warn({
                content: `لطفا تاریخ شروع و پایان را وارد نمایید`,
                className: 'toast-class'
            });
        }

            if( value.length<1 || value_type.length<1){
                return message.warn({
                    content: `لطفا مقدار و نوع را وارد نمایید`,
                    className: 'toast-class'
                });
            }

        if( max_count.length<1 || max_consumed.length<1){
            return message.warn({
                content: `لطفا تعداد و حداکثر تعداد استفاده را وارد نمایید`,
                className: 'toast-class'
            });
        }


        const selectedObjects = optionsValue.map((val) =>
            options.find((option) => option.value === val)
        );

        let obj ={
            title:title,
            services:selectedObjects,
            min_buy_amount:min_buy_amount,
            start_date:moment(start_date, "jYYYY-jMM-jDD").toISOString(),
            end_date:moment(end_date, "jYYYY-jMM-jDD").toISOString(),
            max_count:max_count,
            max_consumed:max_consumed,
            value_type:value_type,
            value:value,
            status:status
        }

        try{
            console.log("obj ",obj)
            await createDiscountCode_api(obj)
            getCampaigns()
            okAddDrawer()
            return message.success({
                content: `کد تخفیف ایجاد شد`,
                className: 'toast-class'
            });
        }catch (e) {
            console.log(e)
            if(e.response.data.message==="شما بیشتر از ۵۰ کد تخفیف نمیتوانید ایجاد کنید"){
                return message.warn({
                    content: `شما بیشتر از ۵۰ کد تخفیف نمیتوانید ایجاد کنید`,
                    className: 'toast-class'
                });
            }
            return message.warn({
                content: `مشکل در ایجاد کد تخفیف شد`,
                className: 'toast-class'
            });
        }

    }



    const [deleteSpin,set_deleteSpin] = useState(false)
    const deleteCode = async (id)=>{
        set_deleteSpin(true)
        try{
            deleteDiscountCode_api(id)
            getCampaigns()
            set_deleteSpin(false)
        }catch(e){
            set_deleteSpin(false)
            console.log("e : ")
        }
    }
    return(
        <div>
            <Spin spinning={deleteSpin}>
            <div className="data-list">
                {data.map(c=>{
                    return(
                        <CampaignCard edit={editCampaign} campaign={c} deleteCode={deleteCode}/>
                    )
                })}

            </div>
            </Spin>

            <Drawer  destroyOnClose={true}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={cancelAddDrawer} visible={addDrawerVisible}>
                <Drawer destroyOnClose={true}  bodyStyle={{padding:0,margin:0}}  width={300} closable={false}  onClose={cancelCodeDrawer} visible={codeDrawerVisible}>
                    <Codes id={campId}/>
                </Drawer>
                <div className="dumbbell-drawer-header">
                    <span>{campId?"ویرایش کد تخفیف":"ایجاد کد تخفیف"}</span>
                </div>
                <div className="dumbbell-add-trainee-form">
                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={title}
                            onChange={e=>set_title(e.target.value)}
                            type="text"/>
                        <span>عنوان</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: 160,
                            }}
                            value={optionsValue}
                            placeholder="انتخاب خدمات"
                            onChange={handleChange}
                            options={options}
                        />
                        <span>بروی خدمات</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={min_buy_amount}
                            onChange={e=>set_min_buy_amount(e.target.value)}
                            type="text"/>
                        <span>حداقل پرداخت</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={start_date}
                            onChange={e=>set_start_date(e.target.value)}
                            type="text" placeholder={"1403-05-02"}/>
                        <span>تاریخ شروع</span>
                    </div>
                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={end_date}
                            onChange={e=>set_end_date(e.target.value)}
                            type="text" placeholder={"1403-05-18"}/>
                        <span>تاریخ پایان</span>
                    </div>
                    {/*<div className="dumbbell-add-trainee-form-item">*/}
                    {/*    <select*/}
                    {/*        value={type}*/}
                    {/*        onChange={e=>set_type(e.target.value)}*/}
                    {/*        className="option-select" name="" id="">*/}
                    {/*        <option value="before_buy">قبل از خرید</option>*/}
                    {/*        <option value="after_buy_discount_code">بعد از خرید - کد تخفیف</option>*/}
                    {/*    </select>*/}
                    {/*    <span>تخفیف بصورت</span>*/}
                    {/*</div>*/}



                        <div className="nested-form">
                            <div className="dumbbell-add-trainee-form-item">
                                <select
                                    value={value_type}
                                    onChange={e=>set_value_type(e.target.value)}
                                    className="option-select" name="" id="">
                                    <option value="amount">مبلغی</option>
                                    <option value="percent">درصدی</option>
                                </select>
                                <span>نوع</span>
                            </div>
                            <div className="dumbbell-add-trainee-form-item">
                                <input
                                    value={value}
                                    onChange={e=>set_value(e.target.value)}
                                    type="text"/>
                                <span>مقدار</span>
                            </div>

                        </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={max_count}
                            onChange={e=>set_max_count(e.target.value)}
                            type="text"/>
                        <span>تعداد</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            value={max_consumed}
                            onChange={e=>set_max_consumed(e.target.value)}
                            type="text"/>
                        <span> حداکثر تعداد استفاده</span>
                    </div>

                    <div className="dumbbell-add-trainee-form-item">
                        <input
                            checked={status==="true"}
                            onClick={e=>set_status(status==="true"?"false":"true")}
                            type="checkbox"/>
                        <span>فعال کردن کد</span>
                    </div>


                    {campId?
                        <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                            handleEditCampaign()
                        }}>
                            <span>ویرایش کد تخفیف</span>
                        </div>:
                        <div className="dumbbell-add-trainee-form-submit-send-invoice" onClick={e=>{
                            addCampaign()
                        }}>
                            <span>ایجاد کد تخفیف</span>
                        </div>
                    }

                    {campId?
                        <div>
                            <div className="dumbbell-add-trainee-form-submit-send-invoice" >
                                <span onClick={e=>{
                                    showCodeDrawer()
                                }} className="code-list-btn">لیست کدها</span>
                            </div>
                        </div>:null}



                </div>




            </Drawer>

            <div className="float-add-btn-v2 clickable" onClick={e=>{
                showAddDrawer()
            }}>
                <img src={plusWhiteIcon} alt=""/>
            </div>



        </div>
    )
}

export default  DiscountCode
