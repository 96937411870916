import React, {Fragment, useEffect, useState} from 'react';
import "./DumbbellCallbackSubscription.scss";
import loginImage from  "../../assets/dumbbell_image/login.png";
import logoWhite from  "../../assets/dumbbell_image/logo-white.png";
import mobileIcon from  "../../assets/dumbbell_image/mobile_icon.png";
import {Spin,message} from "antd";
import {
    addTraineeApi, completeRequestApi,
    landVerifyInvoiceApi,
    landViewInvoiceAuthorityApi, landViewInvoiceRequestApi,
    loginApi, oneSubscriberWithAuthority,
    verifyInvoiceApi, verifySubscriptionPay,
    viewInvoiceApi,
    viewInvoiceAuthorityApi
} from "../../Services/Services";
import "antd/dist/antd.css"
import {useHistory,useLocation,useParams} from "react-router";
import {numberWithCommas, validation} from "../../Services/utils";
import moment from "moment-jalaali";
import {CopyToClipboard} from "react-copy-to-clipboard";

function DumbbellCallbackSubscription(){

    const search = useLocation().search;
    const authority=new URLSearchParams(search).get("Authority");
    const status=new URLSearchParams(search).get("Status");

    const {requestId} = useParams()

    useEffect(()=>{
        document.body.style.backgroundColor = "#8A86FE";
    },[])

    const history = useHistory()
    const [spin,set_spin] = useState(true)
    const [mode,set_mode] = useState("default") //default - invoice - failed - success
    const [data,set_data] = useState({})


    useEffect(()=>{
        console.log(authority)
        console.log(status)
        getInvoice()
    },[])


    const getInvoice = async ()=>{
        let resp = null;
        try{
            if(authority){
                resp = await oneSubscriberWithAuthority(authority)
                set_data(resp.data.data)
                if(status==="OK" && authority.length>5){
                    verifyProcess()
                }else {
                    set_spin(false)
                    set_mode("failed")
                }
            }
        }catch (e) {
            set_spin(false)
            set_mode("failed")
            console.log(e)
        }
    }


    const verifyProcess = async ()=>{
        try{
            await verifySubscriptionPay(authority)
            set_mode("success")
            set_spin(false)
        }catch (e) {
            set_spin(false)
            set_mode("failed")
        }
    }

    return(
        <div className="dumbbell-login-container">



            <Spin spinning={spin}>

                <div className="dumbbell-login-default">

                    <div className="dumbbell-invoice-page callback-page" style={{paddingBottom:20,paddingTop:20}}>

                        <div className="dumbbell-invoice-page-title">
                            <span>{mode==="success"?"پرداخت شد":""}</span>
                            <span>{mode==="failed"?"پرداخت ناموفق":""}</span>
                        </div>



                        {mode==="success"?
                            <Fragment>
                                <div className="dumbbell-callback-message-success">
                                    <span className="dumbbell-callback-message-title">پرداخت شما با موفقیت انجام شد</span>
                                </div>
                                <div className="callback-table">
                                    <div className="callback-table-row">
                                        <span> {numberWithCommas(data?.total_price)} تومان</span>
                                        <label>مبلغ پرداخت شده</label>
                                    </div>
                                    <div className="callback-table-row">
                                        <span>{data?.subscriptions[0]?.title}</span>
                                        <label>نام اشتراک</label>
                                    </div>
                                </div>

                                <div className="callback-track-link ">
                                    <p>اشتراک {data?.subscriptions[0]?.title} برای شما ثبت و فعال شد</p>
                                    {/*<p>شما میتوانید برای پیگیری آخرین وضعیت از لینک زیر استفاده نمایید</p>*/}
                                    {/*<span>{"sdfsfsdfdsfw453tr4534cfsdf"}</span>*/}
                                    {/*<CopyToClipboard*/}
                                    {/*    text={`https://app.step-space.com/plan/sdfsfsdfdsfw453tr4534cfsdf`}*/}
                                    {/*    onCopy={e => {*/}
                                    {/*        message.success({*/}
                                    {/*            content: `لینک پیگیری کپی شد`,*/}
                                    {/*            className: 'toast-class'*/}
                                    {/*        });*/}
                                    {/*    }}>*/}
                                    {/*    <button className="clickable">کپی کردن لینک پیگیری</button>*/}
                                    {/*</CopyToClipboard>*/}
                                </div>


                                {/*<div onClick={e=>{*/}

                                {/*}} className="callback-return clickable">*/}
                                {/*    <span>برگشت به صفحه</span>*/}
                                {/*</div>*/}

                            </Fragment>
                            :null
                        }

                        {mode==="failed"?<div className="dumbbell-callback-message-failed">
                            <span className="dumbbell-callback-message-title">پرداخت ناموفق</span>
                        </div>:null}

                        <div onClick={e=>{
                            if(data?.slug){
                                history.push(`/page/${data.slug}`)
                            }
                        }} className="callback-return clickable">
                            <span>برگشت به صفحه</span>
                        </div>

                    </div>


                    <br/><br/>

                </div>


            </Spin>

        </div>
    )
}

export default DumbbellCallbackSubscription;
