import React,{useEffect,useState} from "react"
import {useParams} from "react-router";
import {
    getBookingTrack_api,
    getOrderTrack_api,
    getPlanTrack_api,
    getSubscriptionTrack_api
} from "../../Services/Services";
import "./Track.scss"
import {useDispatchLoading} from "../../Hook/useDispatchLoading";
import moment from "moment-jalaali";
function Track(){
    const {trackType,trackId} = useParams()
    const [plan,set_plan] = useState(null)
    const [booking,set_booking] = useState(null)
    const [order,set_order] = useState(null)
    const [subscription,set_subscription] = useState({})
    const [not_found,set_not_found] = useState(false)
    const setLoader = useDispatchLoading()

    function labelStatusColor(status,pay_status) {

        if(status==="default" && pay_status==="default")
            return "plan-wait-for-pay"

        if(status==="default" && pay_status==="payed")
            return "plan-wait-for-deliver"

        if(status==="delivered" && pay_status==="payed")
            return "plan-delivered"

        if(status==="rejected" && pay_status==="rejected")
            return "plan-rejected"

        if(status==="default" && pay_status==="wait_for_accept")
            return "plan-wait-for-accept"
    }

    function labelStatus(status,pay_status) {
        if(status==="default" && pay_status==="default")
            return "منتظر پرداخت"

        if(status==="default" && pay_status==="payed")
            return "منتظر تحویل"

        if(status==="delivered" && pay_status==="payed")
            return "تحویل داده شده"

        if(status==="rejected" && pay_status==="rejected")
            return "رد شده"

        if(status==="default" && pay_status==="wait_for_accept")
            return "منتظر تایید"
    }




    const bookBackColor = (status,p_status)=>{


        console.log("status :",status)

        if(p_status==="wait_for_accept"){
            return "book-status booking-turn-pending-status"
        }

        if(status==="booked"){
            return "book-status booking-turn-booked-status"
        }

        if(status==="cancel_me"){
            return "book-status booking-turn-cancel-me-status"
        }

        if(status==="cancel_client"){
            return "book-status booking-turn-cancel-client-status"
        }

        if(status==="accepted"){
            return "book-status booking-turn-accepted-status"
        }

        if(status==="not_come"){
            return "dumbbell-booking-mg-booking-item-header booking-turn-not-come-status"
        }

        return "dumbbell-booking-mg-booking-item-header booking-turn-booked-status"

    }


    const orderStatusLabel = (status)=>{

        if(status==="created"){
            return "در حال بررسی"
        }

        if(status==="payed"){
            return "پرداخت شما تایید شد و اشتراک برای شما ثبت و فعال شد"
        }

        if(status==="done"){
            return "پرداخت شما تایید شد و اشتراک برای شما ثبت و فعال شد"
        }



        if(status==="wait_for_accept"){
            return "در انتظار تایید"
        }

        if(status==="cancel_by_customer"){
            return "کنسل شده توسط مشتری"
        }

        if(status==="cancel_by_owner"){
            return "کنسل شده توسط فروشگاه"
        }

        if(status==="send"){
            return "ارسال شده"
        }

        if(status==="packing"){
            return "در حال آماده سازی"
        }

        if(status==="ready_to_deliver"){
            return "آماده تحویل حضوری"
        }

        if(status==="delivered"){
            return "تحویل شده"
        }


        return ""

    }



    const orderStatusColor = (status)=>{

        if(status==="created"){
            return "#8D89FD"
        }
        if(status==="wait_for_accept"){
            return "#8D89FD"
        }

        if(status==="payed"){
            return "#55c98d"
        }

        if(status==="done"){
            return "#55c98d"
        }

        if(status==="cancel_by_customer"){
            return "#FA8298"
        }

        if(status==="cancel_by_owner"){
            return "#FA8298"
        }

        if(status==="send"){
            return "#55c98d"
        }

        if(status==="packing"){
            return "#FF9031"
        }

        if(status==="ready_to_deliver"){
            return "#55c98d"
        }

        if(status==="delivered"){
            return "#B0B0BD"
        }


        return "رزرو شده"

    }



    const convertStatusToName = (status,p_status)=>{

        if(p_status==="wait_for_accept"){
            return "منتظر تایید رسید"
        }

        if(status==="booked"){
            return "رزرو شده"
        }

        if(status==="cancel_me"){
            return "کنسل شده"
        }

        if(status==="cancel_client"){
            return "کنسل توسط مشتری"
        }

        if(status==="accepted"){
            return "پذیرش شده"
        }

        if(status==="not_come"){
            return "مراجعه نکرده"
        }

        return "رزرو شده"

    }


    useEffect(e=>{
        setLoader(true)
        if(trackId && trackType && trackType ==="plan")
            return getPlanTrack()
        if(trackId && trackType && trackType ==="booking")
            return getBookingTrack()
        if(trackId && trackType && trackType ==="order")
            return getOrderTrack()
        if(trackId && trackType && trackType ==="subscription")
            return getSubscriptionTrack()

        setLoader(false)
    },[])

    const getPlanTrack = async ()=>{
        try{
            const p = await getPlanTrack_api(trackId)
            setLoader(false)
            set_plan(p.data.data)
        }catch (e) {
            setLoader(false)
            set_not_found(true)
            console.log(e)
        }
    }
    const getBookingTrack = async ()=>{
        try{
            const b = await getBookingTrack_api(trackId)
            setLoader(false)
            set_booking(b.data.data)
        }catch (e) {
            setLoader(false)
            set_not_found(true)
            console.log(e)
        }
    }
    const getOrderTrack = async ()=>{
        try{
            const b = await getOrderTrack_api(trackId)
            setLoader(false)
            set_order(b.data.data)
        }catch (e) {
            setLoader(false)
            set_not_found(true)
            console.log(e)
        }
    }

    const getSubscriptionTrack = async ()=>{
        try{
            const b = await getSubscriptionTrack_api(trackId)
            setLoader(false)
            console.log("b : ",b.data.data.status)
            set_subscription(b.data.data)
        }catch (e) {
            setLoader(false)
            set_not_found(true)
            console.log(e)
        }
    }
    return(
        <div className="track-container">

            {subscription?.status?
                <div className="track-title">
                    <h4>{`آخرین وضعیت ثبت نام با شماره پیگیری زیر : `}</h4>
                </div>:null}

            {order?.status?
                <div className="track-title">
                    <h4>{`آخرین وضعیت سفارش  با شماره پیگیری زیر : `}</h4>
                </div>:null}

            {plan?.status?
            <div className="track-title">
                <h4>{`آخرین وضعیت پکیج  با عنوان ${plan.title} `}</h4>
                <br/>
                <span className={`${labelStatusColor(plan.status,plan.pay_status)}`}>{labelStatus(plan.status,plan.pay_status)}</span>
            </div>:null}

            <div className="track-id">
                <h1>{trackId}</h1>
                <span>شماره پیگیری</span>
            </div>


            {order?.status ?
                <div className="track-order-status" style={{backgroundColor:orderStatusColor(order.status)}}>
                    <span>
                        {orderStatusLabel(order.status)}
                    </span>
                    {order.status_description?
                        <p>{order.status_description}</p>:null
                    }
                </div>:null}



            {subscription?.status ?
                <div className="track-order-status" style={{backgroundColor:orderStatusColor(subscription.status)}}>
                    <span>
                        {orderStatusLabel(subscription.status)}
                    </span>
                    {subscription.status_description?
                        <p>{subscription.status_description}</p>
                        :null}

                </div>:null}


            {plan?.status?
                <div className="track-found-plan">
                     {/*<p>در صورت نیاز با شماره 092833 تماس بگیرید</p>*/}
                </div>:null}

                <div className="book-status-sec">
                    {booking?.status?
                        <div className="track-found-booking">
                            <h4>{`آخرین وضعیت رزرو  در تاریخ ${moment(booking.date,'YYYY-MM-DD').format('jYYYY/jMM/jDD')} برای ساعت ${booking.startAt} `}</h4>
                            <span className={`${bookBackColor(booking.status,booking.pay_status)}`}>{convertStatusToName(booking.status,booking.pay_status)}</span>
                            {/*<p>در صورت نیاز با شماره 092833 تماس بگیرید</p>*/}
                        </div>:null
                    }
                </div>

            {not_found?
                <div className="track-not-found">
                    <p>این شماره پیگیری معتبر نمیباشد</p>
                </div>:null
            }

            {/*<div className="receipt-hint">*/}
            {/*    <p>رسید شما پس از بررسی و تایید </p>*/}
            {/*</div>*/}
        </div>
    )
}

export default Track
