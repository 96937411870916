import React,{useEffect,useState} from 'react';
import "./DumbbellPayList.scss";
import DumbbellHeader from "../../Components/DumbbellHeder/DumbbellHeder";
import searchIcon from "../../assets/dumbbell_image/search-icon.png"
import messageIcon from "../../assets/dumbbell_image/message-icon.png"
import traineeFaceIcon from "../../assets/dumbbell_image/sample-trainee-face.png"
import caretDownIcon from "../../assets/dumbbell_image/V2/caret_down_icon.svg";
import {getTransactionList} from "../../Services/Services";
import {convertMonthNumberToName, numberWithCommas} from "../../Services/utils";
import moment from "moment-jalaali";
import mobileIcon from "../../assets/dumbbell_image/V2/mobile_icon_v2.png";
import mailIcon from "../../assets/dumbbell_image/V2/mail_icon_v2.png";
import {Drawer} from "antd";
import {useDispatchLoading} from "../../Hook/useDispatchLoading";

let page =0
let search =null
let startAt =null
let endAt =null

function paymentType(t){
    if(t.ipg)
        return "خرید از درگاه"
    else
        return "خرید با فاکتور"
}

function convertTransactionTypeToName(t){

    if(t.mode==="book"){
        return "رزرو نوبت"
    }

    if(t.mode==="subscription"){
        return "خرید اشتراک"
    }

    if(t.mode==="plan"){
        return "خرید پکیج"
    }

    if(t.mode==="buy"){
        return "خرید از فروشگاه"
    }

    if(t.mode==="reward"){
        return "جایزه استپ"
    }

    switch (t) {
        case 'pay_plan':
            return "خرید پکیج از صفحه"
            break;
        case 'pay_turn':
            return "رزرو نوبت"
            break;
        case 'pay_invoice':
            return "خرید پکیج"
            break;
        case 'buy_reward':
            return "دریافت کارت جایزه"
            break;

    }
}


// function convertTransactionTypeToName(t){
//     switch (t) {
//         case 'pay_plan':
//             return "خرید پکیج از صفحه"
//             break;
//         case 'pay_turn':
//             return "رزرو نوبت"
//             break;
//         case 'pay_invoice':
//             return "خرید پکیج"
//             break;
//         case 'buy_reward':
//             return "دریافت کارت جایزه"
//             break;
//
//     }
// }

function DumbbellPayList(){
    const setLoader = useDispatchLoading()
    useEffect(()=>{
        getTransactions()
    },[])

    const [searchInput,set_searchInput] = useState("")
    const [transactions,set_transactions] = useState([])
    const getTransactions = async ()=>{
        setLoader(true)
        try{
            const response =await getTransactionList(page,search,startAt,endAt)
            set_transactions(response.data.data)
            setLoader(false)
        }catch (e) {
            setLoader(false)
            console.log(e)
        }
    }

    const handleSearch = (word)=>{
        set_searchInput(word)
        if(word.length>2){
            page = 0;
            search = word;
            getTransactions()
        }
        if(word.length===0){
            page = 0;
            search = "";
            getTransactions()
        }
    }


    const [selectedMonth,set_selectedMonth] = useState("all")
    const [selectedYear,set_selectedYear] = useState("1403")


    //Month Drawer
    const [visibleMonthBottomDrawer,set_visibleMonthBottomDrawer] = useState(false)

    const showMonthBottomDrawer = ()=>{
        set_visibleMonthBottomDrawer(!visibleMonthBottomDrawer)
    }

    const onMonthBottomDrawerClose = () => {
        set_visibleMonthBottomDrawer(false);
    };

    const handleClickMonthBottomDrawer = (v)=>{
        set_selectedMonth(v)
        set_visibleMonthBottomDrawer(false)

        if(v==="all"){
            page=0;
            let nextYear = parseInt(selectedYear)+1
            let startTime = selectedYear+"-01-01"
            let endTime = nextYear+"-01-01"
            startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            getTransactions()
        }else {
            page=0;
            let month = parseInt(v);
            let nextMonth = parseInt(v)+1;

            if(month<10)
                month = "0"+month

            if(nextMonth<10)
                nextMonth = "0"+nextMonth

            let startTime = selectedYear+"-"+month+"-"+"01"
            let endTime = selectedYear+"-"+nextMonth+"-"+"01"
            startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            getTransactions()
        }

    }

    //Year Drawer
    const [visibleYearBottomDrawer,set_visibleYearBottomDrawer] = useState(false)

    const showYearBottomDrawer = ()=>{
        set_visibleYearBottomDrawer(!visibleYearBottomDrawer)
    }

    const onYearBottomDrawerClose = () => {
        set_visibleYearBottomDrawer(false);
    };

    const handleClickYearBottomDrawer = (v)=>{
        set_selectedYear(v)
        set_visibleYearBottomDrawer(false)
        page=0;

        if(selectedMonth==="all"){
            let nextYear = parseInt(v)+1
            let startTime = v+"-01-01"
            let endTime = nextYear+"-01-01"
            startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            getTransactions()
        }else {

            let month = parseInt(selectedMonth);
            let nextMonth = parseInt(selectedMonth)+1;

            if(month<10)
                month = "0"+month

            if(nextMonth<10)
                nextMonth = "0"+nextMonth

            let startTime = v+"-"+month+"-01"
            let endTime = v+"-"+nextMonth+"-01"
            startAt = moment(startTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            endAt = moment(endTime,"jYYYY-jMM-jDD").format('YYYY-MM-DD')
            getTransactions()

        }

    }

    return(
        <div className="dumbbell-trainee-container">

            <DumbbellHeader title={"پرداخت ها"}/>

            <div className="page-wrapper">

                <div className="search-row">
                    <img src={searchIcon} alt=""/>
                    <input
                        value={searchInput}
                        onChange={e=>handleSearch(e.target.value)}
                        type="text" name="" id="" placeholder="جستجو با موبایل یا نام"/>
                </div>
                <div className="filter-row">
                    <div className="filter-item clickable margin-right" onClick={showMonthBottomDrawer}>
                        <img src={caretDownIcon} alt=""/>
                        <span>{selectedMonth==="all"?"همه ماهها":convertMonthNumberToName(selectedMonth)}</span>
                    </div>
                    <div className="filter-item clickable" onClick={showYearBottomDrawer}>
                        <img src={caretDownIcon} alt=""/>
                        <span>{selectedYear}</span>
                    </div>
                </div>


                {/*<div className="dumbbell-pay-total-sec">*/}
                {/*    <span className="dumbbell-pay-total-price">350,000هزارتومان</span>*/}
                {/*    <span className="dumbbell-pay-total-count">10 پرداخت</span>*/}
                {/*</div>*/}

                <br/>
                <div className="dumbbell-trainee-list-trainer">

                    {/*Card*/}

                    {transactions.map(t=>{
                        return(
                            <div className="dumbbell-pay-card-trainer">
                                <div className="dumbbell-pay-card-message">
                                    <span className="dumbbell-pay-value-trainer">{numberWithCommas(t.amount)}</span>
                                    <span className="dumbbell-pay-unit">تومان</span>
                                    <span className="trx-input">واریز</span>
                                </div>
                                <div className="dumbbell-trainee-card-trainee dashed">
                                    <div className="dumbbell-trainee-card-trainee-info">
                                        <div className="dumbbell-trainee-card-trainee-info-row">
                                            <span className="val">{`${t.client_first_name} ${t.client_last_name}`}</span>
                                            <span className="key">واریز کننده : </span>
                                        </div>
                                        <div className="dumbbell-trainee-card-trainee-info-row">
                                            <span className="val">{t.client_mobile}</span>
                                            <span className="key">موبایل  : </span>
                                        </div>
                                        <div className="dumbbell-trainee-card-trainee-info-row">
                                            <span className="val">{convertTransactionTypeToName(t)}</span>
                                            <span className="key">بابت : </span>
                                        </div>
                                        <div className="dumbbell-trainee-card-trainee-info-row">
                                            <span className="val">{paymentType(t)}</span>
                                            <span className="key">نوع پرداخت : </span>
                                        </div>


                                        <div className="dumbbell-trainee-card-trainee-info-row">
                                            <span className="val">{moment(t.client_pay_date).format('jYYYY-jMM-jDD HH:mm')}</span>
                                            <span className="key">تاریخ واریزی :  </span>
                                        </div>

                                        <div className="dumbbell-trainee-card-trainee-info-row">
                                            <span className="val">{t.ref_id_client}</span>
                                            <span className="key">شماره پیگیری  : </span>
                                        </div>

                                        <div className="dumbbell-trainee-card-trainee-info-row">
                                            <span className="val">{t.trainer_checkout_date?
                                                moment(t.trainer_checkout_date).format('jYYYY-jMM-jDD HH:mm')
                                                : "در انتظار تسویه"}</span>
                                            <span className="key">واریز از استپ  : </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>


            <Drawer height={300}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onYearBottomDrawerClose} visible={visibleYearBottomDrawer}>

                <div className="bottom-drawer-handle">
                    <span></span>
                </div>
                <div className="bottom-drawer-body">

                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1403")
                    }}>
                        <span className="label">1403</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1404")
                    }}>
                        <span className="label">1404</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1405")
                    }}>
                        <span className="label">1405</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1406")
                    }}>
                        <span className="label">1406</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1407")
                    }}>
                        <span className="label">1407</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1408")
                    }}>
                        <span className="label">1408</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1409")
                    }}>
                        <span className="label">1409</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1410")
                    }}>
                        <span className="label">1410</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1411")
                    }}>
                        <span className="label">1411</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1412")
                    }}>
                        <span className="label">1412</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickYearBottomDrawer("1413")
                    }}>
                        <span className="label">1413</span>
                    </div>

                </div>
            </Drawer>

            <Drawer height={300}
                    bodyStyle={{padding:0,margin:0,backgroundColor:"#8A86FE",borderRadius:"15px 15px 0 0"}}
                    mask={true}  closable={false}  placement="bottom" onClose={onMonthBottomDrawerClose} visible={visibleMonthBottomDrawer}>

                <div className="bottom-drawer-handle">
                    <span></span>
                </div>
                <div className="bottom-drawer-body">
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("all")
                    }}>
                        <span className="label">همه ماها</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("01")
                    }}>
                        <span className="label">فرودردین</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("02")
                    }}>
                        <span className="label">اردیبهشت</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("03")
                    }}>
                        <span className="label">خرداد</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("04")
                    }}>
                        <span className="label">تیر</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("05")
                    }}>
                        <span className="label">مرداد</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("06")
                    }}>
                        <span className="label">شهریور</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("07")
                    }}>
                        <span className="label">مهر</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("08")
                    }}>
                        <span className="label">آبان</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("09")
                    }}>
                        <span className="label">آذر</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("10")
                    }}>
                        <span className="label">دی</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("11")
                    }}>
                        <span className="label">بهمن</span>
                    </div>
                    <div className="bottom-drawer-item clickable" onClick={e=>{
                        handleClickMonthBottomDrawer("12")
                    }}>
                        <span className="label">اسفند</span>
                    </div>

                </div>
            </Drawer>
        </div>
    )
}



export default DumbbellPayList;
